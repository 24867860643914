import React from 'react'
import { PageWrap, Pricing, SEO } from '../components'

const PricingPage = () => (
	<>
		<SEO title="Pricing" />
		<PageWrap>
			<Pricing />
		</PageWrap>
	</>
)

export default PricingPage
